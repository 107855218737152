import axios from "axios";
const SERVER_URL = "https://api.radiotal.me";

export const bGetStationById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}/stations/prettyId/` + id, { withCredentials: true })
      .then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
  });
};
