import React, { useState, useEffect } from "react";
import "./App.scss";
import styled from "styled-components";
import QRCode from "qrcode.react";
import ReactGA from "react-ga4";
import { Heading2, Heading4, Row, Col, Separator } from "singulier";
import { bGetStationById } from "./backend/stations";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

const Card = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: #130f40;
  width: 80vw;
  max-width: 1400px;

  min-height: 70vh;

  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    min-height: 100vh;
    border-radius: 0;
  }

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const InfoText = styled.div`
  color: #ffffff;
`;

const QrWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: #30336b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const StationImage = styled.img`
  width: 256px;
  max-height: 256px;
  max-width: 80vw;
  position: absolute;
  left: 40px;
  top: -40px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  @media (max-width: 1500px) {
    width: 128px;
    height: 128px;
  }

  @media (max-height: 1100px) {
    width: 128px;
    height: 128px;
  }

  @media (max-width: 1200px) {
    top: 20px;
  }

  @media (max-width: 1000px) {
    width: 256px;
    height: unset;
    max-width: 90vw;
    position: unset;
  }
`;

const PlayInAppButton = styled.button`
  border: 0;
  border-radius: 8px;
  font-size: 17px;
  color: #ffffff;
  background-color: #30336b;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(1.3);
  }
`;

const GetAppButton = styled(PlayInAppButton)``;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Tag = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #30336b;
  color: #ffffff;
  font-size: 13px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const HideMobile = styled.div`
  display: block;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const centered = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};

const Logo = styled.img`
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;

  @media (max-width: 1000px) {
    position: unset;
    margin: 10px 0 20px 0;
  }
`;

const ASSET_FOLDER = "https://static.soundresidence.com/radiotal/";

function App() {
  const [station, setStation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [url, setUrl] = useState("");

  const { trackPageView } = useMatomo();

  useEffect(() => {
    ReactGA.initialize("G-RR9MSMKH6W");
    ReactGA.pageview(window.location.pathname + window.location.search);
    trackPageView();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stationId = urlParams.get("id");

    if (stationId) {
      setUrl("radiotal://" + stationId);
      setIsLoading(true);
      bGetStationById(stationId)
        .then((s) => {
          if (!s) {
            setIsLoading(false);
            setInvalid(true);
            return;
          }
          setIsLoading(false);
          setStation(s);
        })
        .catch((e) => {
          setIsLoading(false);
          setInvalid(true);
        });
    } else {
      setIsLoading(false);
      setInvalid(true);
    }
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <Card style={centered}>
          <InfoText>loading...</InfoText>
        </Card>
      </Wrapper>
    );
  }

  if (!isLoading && invalid) {
    return (
      <Wrapper>
        <Card style={centered}>
          <InfoText>invalid URL.</InfoText>
        </Card>
      </Wrapper>
    );
  }

  return station ? (
    <Wrapper>
      <Card style={centered}>
        <a href={"https://www.radiotal.com"} target="_blank">
          <Logo src={require("./assets/logo-white-small.png").default} />
        </a>

        {station.image ? (
          <StationImage src={ASSET_FOLDER + station.image} />
        ) : null}

        <Row>
          <Col style={{ ...centered }} factor="col-xl-12">
            <Heading2>{station.name}</Heading2>

            <HideMobile>
              {station.country && (
                <>
                  <Separator size={20} />
                  <InfoText>Country</InfoText>
                  <Heading4>{station.country}</Heading4>
                </>
              )}
              {station.homepage && (
                <>
                  <Separator size={20} />
                  <InfoText>Homepage</InfoText>
                  <a
                    href={station.homepage}
                    target="_blank"
                    style={{ color: "#ffffff" }}
                  >
                    <Heading4>{station.homepage}</Heading4>
                  </a>
                </>
              )}

              {station.language && (
                <>
                  <Separator size={20} />
                  <InfoText>Language</InfoText>
                  <Heading4>{station.language}</Heading4>
                </>
              )}

              {station.tags ? (
                <>
                  <Separator size={20} />
                  <InfoText>Tags</InfoText>
                  <TagContainer>
                    {station.tags.split(",").map((t) => (
                      <Tag key={t}>{t}</Tag>
                    ))}
                  </TagContainer>
                </>
              ) : null}
            </HideMobile>
          </Col>
          <Col style={{ ...centered }} factor="col-xl-12">
            <InfoText>using your phone, click the following button</InfoText>

            <a href={url}>
              <PlayInAppButton>open in Radiotal</PlayInAppButton>
            </a>

            <HideMobile>
              <Separator size={20} />
              <InfoText>or scan the code below</InfoText>
              <QrWrapper>
                <QRCode
                  value={url}
                  size={256}
                  bgColor="#30336b"
                  fgColor="#ffffff"
                />
              </QrWrapper>
            </HideMobile>

            <Separator size={20} />
            <InfoText>don't have Radiotal installed?</InfoText>
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.radiotal"
              }
              target="_blank"
            >
              <GetAppButton>get the app</GetAppButton>
            </a>
          </Col>
        </Row>
      </Card>
      <Separator size={20} />
      <InfoText>Copyright &copy; Radiotal 2021.</InfoText>
      <Separator size={20} />
    </Wrapper>
  ) : (
    <Wrapper />
  );
}

export default App;
